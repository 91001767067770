body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


option:hover {
  background-color: rgb(6, 184, 149); /* poner el color que gustes */
}


.tooltip-inner {
  background-color: #009966;
  }
  .tooltip.bs-tooltip-right .arrow:before {
      border-right-color: #009966 !important;
  }
  .tooltip.bs-tooltip-left .arrow:before {
      border-left-color: #009966 !important;
  }
  .tooltip.bs-tooltip-bottom .arrow:before {
      border-bottom-color: #009966 !important;
  }
  .tooltip.bs-tooltip-top .arrow:before {
      border-top-color: #009966 !important;
  }